import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import style from '../modules/404.module.scss';

const NotFoundPage = (props) => (
  <Layout {...props}>
    <SEO title="404: Page not found" />
    <div id={style.NotFound}>
      <div className="wrapper">
        <h1 className="t1 mb1">Page not found</h1>
        <p>This page doesn't exist.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
